<template>
  <visualization-frame>
    <visualization-header title="Sample" :export-options="exportOptions" />
    <mode-selector
      :modes="this.samples.modes"
      :current-mode="samples.currentMode"
      @modeChanged="samples.currentMode = $event"
    />
    <Plotly
      ref="plotly"
      :data="heatData"
      :layout="layout"
      :display-mode-bar="false"
      :watchShallow="true"
    />
  </visualization-frame>
</template>

<script>
import { Plotly } from 'vue-plotly'
import VisualizationFrame from '@/components/resultsarea/visualization/visualization-frame'
import VisualizationHeader from '@/components/resultsarea/visualization/visualization-header'
import ModeSelector from '@/components/resultsarea/visualization/mode-selector'

export default {
  name: 'samples-heatmap-card',
  props: ['samples'],
  components: {
    ModeSelector,
    VisualizationHeader,
    VisualizationFrame,
    Plotly,
  },
  methods: {
    exportImage: function () {
      this.$refs.plotly.downloadImage({
        format: 'png',
        filename: 'piquasso_samples_histogram',
        height: 800,
        width: 800,
      })
    },
    exportCsv: function () {
      const encodedUri = encodeURI(this.samples.csv)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'piquasso_samples.csv')
      document.body.appendChild(link) // Required for FF

      link.click() // This will download the data file named "my_data.csv".
    },
  },
  computed: {
    heatData: function () {
      const modeIndex = this.samples.modes.findIndex(
        (m) => m === this.samples.currentMode
      )
      return [
        {
          x: this.samples.data.map((x) => x[2 * modeIndex]),
          y: this.samples.data.map((x) => x[2 * modeIndex + 1]),
          type: 'histogram2d',
          colorscale: 'YlGnBu',
        },
      ]
    },
  },
  data: function () {
    return {
      exportOptions: [
        { text: 'Export Data', onClick: this.exportCsv },
        { text: 'Export Image', onClick: this.exportImage },
      ],
      layout: {
        font: {
          color: '#FFF',
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        paper_bgcolor: '#2f3b5a',
        // eslint-disable-next-line @typescript-eslint/camelcase
        plot_bgcolor: '#2f3b5a',
        // colorscale: [[0, rgb(220,220,220)], [0.2, rgb(245,195,157)], [0.4, rgb(245,160,105)], [1, rgb(178,10,28)], ]
      },
      colorway: [
        '#1f77b4', // muted blue
        '#ff7f0e', // safety orange
        '#2ca02c', // cooked asparagus green
        '#d62728', // brick red
        '#9467bd', // muted purple
        '#8c564b', // chestnut brown
        '#e377c2', // raspberry yogurt pink
        '#7f7f7f', // middle gray
        '#bcbd22', // curry yellow-green
        '#17becf', // blue-teal
      ],
    }
  },
}
</script>

<style scoped>
.gl-container {
  width: 100%;
  height: 100%;
}

#scene {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}
</style>
