<template>
  <div class="visualization-card-header">
    {{ title }}
    <div style="text-align: right">
      <template v-for="item in exportOptions">
        <b-button
          :key="item.text"
          :id="item.text"
          class="visualization-export"
          @click="
            item.onClick()
            popoverView = false
            popoverTrigger('popoverView')
          "
        >
          {{ item.text }}
          <b-popover
            v-if="item.popoverText"
            custom-class="copy-to-clipboard-popover"
            :target="item.text"
            placement="bottom"
            triggers="manual"
            :show.sync="popoverView"
          >
            {{ item.popoverText }}
          </b-popover>
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'visualization-header',
  props: {
    title: String,
    exportOptions: undefined,
  },
  data: function () {
    return {
      popoverView: false,
    }
  },
  methods: {
    popoverTrigger(id) {
      this[id] = true
      setTimeout(() => {
        this[id] = false
      }, 1500)
    },
  },
}
</script>

<style scoped>
.visualization-card-header {
  color: var(--pq-font-color-shade1);
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: -5px;
}

.visualization-export,
.visualization-export:focus,
.visualization-export:active {
  color: var(--pq-font-color-shade1) !important;
  font-size: 0.75rem;
  background-color: var(--pq-blue-shade4) !important;
  border: none;
  margin: 0 5px;
  box-shadow: none !important;
}

.visualization-export:active {
  transform: scale(1.05);
  transition: all 15ms;
}
</style>
