<template>
  <visualization-frame :cols="0">
    <visualization-header title="Samples" :export-options="exportOptions" />
    <multi-mode-selector
      :modes="this.samples.modes"
      :current-modes="samples.currentModes"
      @modesChanged="samples.currentModes = $event"
    />
    <Plotly
      ref="plotly"
      :data="histData"
      :layout="layout"
      :display-mode-bar="false"
      :watchShallow="true"
    />
  </visualization-frame>
</template>

<script>
import { Plotly } from 'vue-plotly'
import VisualizationFrame from '@/components/resultsarea/visualization/visualization-frame'
import VisualizationHeader from '@/components/resultsarea/visualization/visualization-header'
import MultiModeSelector from '@/components/resultsarea/visualization/multi-mode-selector'

export default {
  name: 'samples-hist-card',
  props: ['samples'],
  components: {
    MultiModeSelector,
    VisualizationHeader,
    VisualizationFrame,
    Plotly,
  },
  methods: {
    exportImage: function () {
      this.$refs.plotly.downloadImage({
        format: 'png',
        filename: 'piquasso_samples_histogram',
      })
    },
    exportCsv: function () {
      const encodedUri = encodeURI(this.samples.csv)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'piquasso_samples.csv')
      document.body.appendChild(link) // Required for FF

      link.click() // This will download the data file named "my_data.csv".
    },
  },
  computed: {
    histData: function () {
      const arr = []
      for (const mode of this.samples.currentModes) {
        const modeIndex = this.samples.modes.findIndex((m) => m === mode)
        arr.push({
          x: this.samples.data.map((x) => x[modeIndex]),
          type: 'histogram',
          name: 'Qumode ' + mode,
          marker: {
            color: this.colorway[modeIndex % this.colorway.length],
          },
        })
      }
      return arr
    },
  },
  data: function () {
    return {
      exportOptions: [
        { text: 'Export Data', onClick: this.exportCsv },
        { text: 'Export Image', onClick: this.exportImage },
      ],
      layout: {
        font: {
          color: '#FFF',
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        paper_bgcolor: '#2f3b5a',
        // eslint-disable-next-line @typescript-eslint/camelcase
        plot_bgcolor: '#2f3b5a',
        // colorscale: [[0, rgb(220,220,220)], [0.2, rgb(245,195,157)], [0.4, rgb(245,160,105)], [1, rgb(178,10,28)], ]
      },
      colorway: [
        '#1f77b4', // muted blue
        '#ff7f0e', // safety orange
        '#2ca02c', // cooked asparagus green
        '#d62728', // brick red
        '#9467bd', // muted purple
        '#8c564b', // chestnut brown
        '#e377c2', // raspberry yogurt pink
        '#7f7f7f', // middle gray
        '#bcbd22', // curry yellow-green
        '#17becf', // blue-teal
      ],
    }
  },
}
</script>

<style>
.gl-container {
  width: 100%;
  height: 100%;
}

#scene {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}
</style>
