<template>
  <transition name="slide">
    <div class="row m-0 w-100" v-if="this.result">
      <fock-probabilities-card :fock-probabilities="result.fockProbabilities" />
      <wigner-function-card
        v-if="hasWignerPlot"
        :wigner-plot="result.wignerPlot"
      />
      <samples-cards v-if="hasSamples" :samples="result.samples" />
      <python-code-card :python-code="result.pythonCode" />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import WignerFunctionCard from '@/components/resultsarea/visualization/wigner-function-card'
import PythonCodeCard from '@/components/resultsarea/visualization/python-code-card'
import FockProbabilitiesCard from '@/components/resultsarea/visualization/fock-probabilities-card'
import SamplesCards from '@/components/resultsarea/visualization/samples-cards'

export default {
  name: 'results-area',
  props: ['result'],
  components: {
    SamplesCards,
    FockProbabilitiesCard,
    PythonCodeCard,
    WignerFunctionCard,
  },
  computed: {
    ...mapGetters(['getCurrentProject']),
    hasSamples: function () {
      return this.result.samples.data.length > 0
    },
    hasWignerPlot: function () {
      return this.result.wignerPlot.data.length > 0
    },
  },
}
</script>

<style scoped>
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(0, 100%);
}
.slide-enter-to {
  transform: translate(0, 0);
}

.slide-leave-to {
  opacity: 0;
}
</style>
