<template>
  <div class="visualization-card-nav">
    <b-form-checkbox-group
      :options="options"
      @change="selectionChange"
      v-model="modesModel"
      size="lg"
      buttons
    >
    </b-form-checkbox-group>
  </div>
</template>

<script>
export default {
  name: 'multi-mode-selector',
  props: ['modes', 'currentModes'],
  methods: {
    selectionChange: function (modes) {
      this.$emit('modesChanged', modes)
    },
  },
  computed: {
    options: function () {
      const arr = []
      for (const mode of this.modes) {
        arr.push({ text: 'Qumode ' + mode, value: mode })
      }
      return arr
    },
  },
  data: function () {
    return {
      modesModel: this.currentModes,
    }
  },
  watch: {
    currentModes() {
      this.modesModel = this.currentModes
    },
  },
}
</script>

<style scoped>
.visualization-card-nav {
  margin-bottom: 1rem;
  text-align: left;
}

::v-deep .btn-secondary {
  font-size: 0.75rem;
  width: fit-content;
  border-bottom: 0;
  border-radius: 10rem;
  border-color: #414d61;
  background-color: #414d61;
  box-shadow: none !important;
}

::v-deep .btn-secondary > span {
  color: var(--pq-font-color-shade1);
}

::v-deep .btn-secondary :hover {
  background-color: #4e5d75;
}

::v-deep .btn-secondary :active {
  transform: scale(1.05);
}

::v-deep .active {
  color: #eee !important;
  background-color: #4e5d75 !important;
  border: 0;
  border-radius: 10rem;
}
</style>
