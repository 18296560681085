<template>
  <div :class="this.setCols" class="col-md-12 mb-4">
    <div class="visualization-frame">
      <div class="visualization-card">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'visualization-frame',
  props: ['cols'],
  computed: {
    setCols() {
      return 'col-xl-' + this.cols
    },
  },
}
</script>

<style scoped>
.visualization-card {
  height: 100%;
  padding: 1rem;
  background-color: var(--pq-blue-shade3);
  border-radius: 0.5rem;
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.1);
}
</style>
