import { render, staticRenderFns } from "./visualization-frame.vue?vue&type=template&id=2803ebdd&scoped=true&"
import script from "./visualization-frame.vue?vue&type=script&lang=js&"
export * from "./visualization-frame.vue?vue&type=script&lang=js&"
import style0 from "./visualization-frame.vue?vue&type=style&index=0&id=2803ebdd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2803ebdd",
  null
  
)

export default component.exports