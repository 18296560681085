<template>
  <visualization-frame :cols="6">
    <visualization-header title="Python Code" :export-options="exportOptions" />
    <highlightjs class="code-block" language="python" :code="pythonCode" />
  </visualization-frame>
</template>

<script>
import VisualizationFrame from '@/components/resultsarea/visualization/visualization-frame'
import VisualizationHeader from '@/components/resultsarea/visualization/visualization-header'
import 'highlight.js'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import 'highlight.js/styles/dracula.css'

export default {
  name: 'python-code-card',
  props: ['pythonCode'],
  components: {
    VisualizationFrame,
    VisualizationHeader,
    highlightjs: hljsVuePlugin.component,
  },
  methods: {
    copyCodeToClipboard: function () {
      this.$utilFunc.copyToClipboard(this.pythonCode, {
        debug: true,
        message: 'Press #{key} to copy',
      })
    },
  },
  data: function () {
    return {
      exportOptions: [
        {
          text: 'Copy to clipboard',
          onClick: this.copyCodeToClipboard,
          popoverText: 'Copied to clipboard!',
        },
      ],
    }
  },
}
</script>

<style scoped>
.code-block {
  margin: 0;
  text-align: left;
}
</style>
<style>
.hljs {
  background-color: unset;
}
</style>
