<template>
  <div class="col-md-12 col-xl-6 p-0">
    <samples-hist-card v-if="showHist()" :samples="samples" />
    <samples-heatmap-card v-else :samples="samples" />
  </div>
</template>

<script>
import SamplesHistCard from '@/components/resultsarea/visualization/samples-hist-card'
import SamplesHeatmapCard from '@/components/resultsarea/visualization/samples-heatmap-card'

export default {
  name: 'samples-cards',
  props: ['samples'],
  components: { SamplesHeatmapCard, SamplesHistCard },
  methods: {
    showHist: function () {
      const histMeasures = ['ParticleNumberMeasurement', 'ThresholdMeasurement']
      return histMeasures.includes(this.samples.type)
    },
  },
}
</script>
