<template>
  <div class="visualization-card-nav">
    <b-nav tabs align="left" class="visualization-card-nav-ul">
      <b-nav-item
        v-for="mode in this.modes"
        :key="mode"
        @click.prevent="selectMode(mode)"
        v-bind:class="activityClass(mode)"
      >
        Qumode {{ mode }}
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: 'mode-selector',
  props: ['modes', 'currentMode'],
  methods: {
    selectMode: function (mode) {
      this.$emit('modeChanged', mode)
    },
    activityClass: function (mode) {
      return this.currentMode === mode ? 'active' : ''
    },
  },
}
</script>

<style scoped>
.visualization-card-nav {
  margin-bottom: 1rem;
}

.visualization-card-nav-ul {
  font-size: 0.75rem;
  width: fit-content;
  color: #909aaa;
  border-radius: 10rem;
  background-color: var(--pq-blue-shade2);
  border-bottom: 0;
}

.visualization-card-nav-ul > li > a {
  border-radius: 10rem;
  border: none;
}

.visualization-card-nav-ul > li > a:hover {
  background-color: var(--pq-blue-shade4);
}

.visualization-card-nav-ul > li > a:active {
  transform: scale(1.05);
}
.nav-link {
  color: var(--pq-font-color-shade1);
}
.active {
  color: #eee !important;
  background-color: var(--pq-blue-shade4) !important;
  border: 0;
  border-radius: 10rem;
}
</style>
