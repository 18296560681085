<template>
  <visualization-frame :cols="12">
    <visualization-header
      title="Particle Number Detection Probabilities"
      :export-options="exportOptions"
    />
    <Plotly
      ref="plotly"
      :data="fockData"
      :layout="layout"
      :display-mode-bar="false"
      :watchShallow="true"
    />
  </visualization-frame>
</template>

<script>
import { Plotly } from 'vue-plotly'
import VisualizationFrame from '@/components/resultsarea/visualization/visualization-frame'
import VisualizationHeader from '@/components/resultsarea/visualization/visualization-header'

export default {
  name: 'fock-probabilities-card',
  props: ['fockProbabilities'],
  components: {
    Plotly,
    VisualizationFrame,
    VisualizationHeader,
  },
  methods: {
    exportImage: function () {
      this.$refs.plotly.downloadImage({
        format: 'png',
        filename: 'piquasso_fock_probabilities',
      })
    },
    exportCsv: function () {
      const encodedUri = encodeURI(this.fockProbabilities.csv)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'piquasso_fock_probabilities.csv')
      document.body.appendChild(link) // Required for FF

      link.click() // This will download the data file named "my_data.csv".
    },
  },
  computed: {
    fockData: function () {
      if (this.fockProbabilities) {
        return [
          {
            x: this.fockProbabilities.x,
            y: this.fockProbabilities.y,
            type: 'bar',
            colorscale: 'YlGnBu',
          },
        ]
      }
      return undefined
    },
  },
  data: function () {
    return {
      layout: {
        font: {
          color: '#FFF',
        },
        xaxis: {
          type: 'category',
          linecolor: '#FFF',
          color: '#FFF',
        },
        yaxis: {
          linecolor: '#FFF',
          color: '#FFF',
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        paper_bgcolor: '#2f3b5a',
        // eslint-disable-next-line @typescript-eslint/camelcase
        plot_bgcolor: '#2f3b5a',
        // colorscale: [[0, rgb(220,220,220)], [0.2, rgb(245,195,157)], [0.4, rgb(245,160,105)], [1, rgb(178,10,28)], ]
      },
      exportOptions: [
        { text: 'Export Data', onClick: this.exportCsv },
        { text: 'Export Image', onClick: this.exportImage },
      ],
    }
  },
}
</script>

<style scoped>
.code-block {
  margin: 0;
  text-align: left;
}
</style>
