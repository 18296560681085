<template>
  <visualization-frame v-if="wigner" :cols="6">
    <visualization-header
      title="Wigner Function"
      :export-options="exportOptions"
    />
    <mode-selector
      :modes="this.wignerPlot.modes"
      :current-mode="wignerPlot.currentMode"
      @modeChanged="wignerPlot.currentMode = $event"
    />
    <Plotly
      ref="plotly"
      :data="wigner"
      :layout="layout"
      :display-mode-bar="false"
      :watchShallow="true"
    />
  </visualization-frame>
</template>

<script>
import { Plotly } from 'vue-plotly'
import VisualizationFrame from '@/components/resultsarea/visualization/visualization-frame'
import VisualizationHeader from '@/components/resultsarea/visualization/visualization-header'
import ModeSelector from '@/components/resultsarea/visualization/mode-selector'

export default {
  name: 'wigner-function-card',
  props: ['wignerPlot'],
  components: {
    ModeSelector,
    VisualizationHeader,
    VisualizationFrame,
    Plotly,
  },
  methods: {
    exportImage: function () {
      this.$refs.plotly.downloadImage({
        format: 'png',
        filename: 'piquasso_wigner_function',
        height: 800,
        width: 1000,
      })
    },
  },
  computed: {
    wigner: function () {
      const index = this.wignerPlot.modes.findIndex(
        (x) => x === this.wignerPlot.currentMode
      )
      const data = this.wignerPlot.data[index].data
      return [
        {
          x: data.x,
          y: data.y,
          z: data.z,
          type: 'surface',
          colorscale: 'YlGnBu',
        },
      ]
    },
  },
  data: function () {
    return {
      exportOptions: [{ text: 'Export Image', onClick: this.exportImage }],
      layout: {
        font: {
          color: '#FFF',
        },
        scene: {
          xaxis: {
            linecolor: '#FFF',
            color: '#FFF',
            title: { text: 'x' },
          },
          yaxis: {
            linecolor: '#FFF',
            color: '#FFF',
            title: { text: 'p' },
          },
          zaxis: {
            linecolor: '#FFF',
            color: '#FFF',
            title: { text: 'W(x,p)' },
          },
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        paper_bgcolor: '#2f3b5a',
        // eslint-disable-next-line @typescript-eslint/camelcase
        plot_bgcolor: '#2f3b5a',
        // colorscale: [[0, rgb(220,220,220)], [0.2, rgb(245,195,157)], [0.4, rgb(245,160,105)], [1, rgb(178,10,28)], ]
      },
    }
  },
}
</script>

<style>
.gl-container {
  width: 100%;
  height: 100%;
}

#scene {
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}
</style>
